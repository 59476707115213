export function btn_report() {
	document.addEventListener("DOMContentLoaded", function () {
		// bodyにボタン表示用クラスがあり、モーダルがまだ表示されていない場合に実行
		if (
			document.body.classList.contains("js-btn-report_show") &&
			!sessionStorage.getItem("btnReportSession")
		) {
			showBtnReport();
		}
	});

	// ボタン表示
	function showBtnReport() {
		const modal = document.querySelector(".js-btn-report");
		modal.classList.add("is-show");

		// 閉じるボタン
		const closeButton = document.querySelector(".js-btn-report_close");
		closeButton.addEventListener("click", function () {
			modal.classList.remove("is-show");
			// 閉じたことをセッションストレージに記録
			sessionStorage.setItem("btnReportSession", "true");
			// スクロールイベント解除
			window.removeEventListener("scroll", handleScroll);
		});

		// スクロールイベント
		window.addEventListener("scroll", handleScroll);
	}

	function handleScroll() {
		const modal = document.querySelector(".js-btn-report");

		// スクロール位置が150px以上の場合にふわっと表示
		if (window.scrollY > 150) {
			modal.classList.add("is-active");
		} else {
			modal.classList.remove("is-active");
		}
	}
}
