// import { tab } from './modules/tab';
// tab();


$(function() {
	// トップページ：タブ表示
	$('.js-tabTrigger').on('click', function() {
		$('.js-tabTrigger').removeClass('is-active');
		$(this).addClass('is-active');
		let tabIndex = $(this).index();
		$('.js-tabContent').removeClass('is-active');
		$('.js-tabContent').eq(tabIndex).addClass('is-active');
	});

	// トップページ：MV
	if ($('.js-mvSlider')[0]) {
		$('.js-mvSlider').slick({
			'autoplay': true,
			'autoplaySpeed': 5000,
			'fade': true,
			'arrows': false,
			'draggable': false,
			'pauseOnFocus': false,
			'pauseOnHover': false,
			'pauseOnDotsHover': false,
			'speed': 2000,
			'swipe': false,
			'touchMove': false
		});
	}

	// IR：サイドバー
	$('.js-sidebarTrigger').on('click', switchDrawer);
});

function switchDrawer() {
	$("html").toggleClass("is-active");
	return false;
}